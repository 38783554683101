import React, { useState } from 'react';
import { Card, CardContent, CardActions, Typography, Accordion, AccordionSummary, AccordionDetails, Button, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemButton, CardHeader, Modal, Slide, Dialog, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formattedTime } from '../../utils/times';
import { formatDate } from '../../utils/dates';
import api, { ClientGoal, WorkoutWeek } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import GoalForm from './goal-form';
import { TransitionProps } from '@mui/material/transitions';
import Transition from '../common/Transition';
import { useTranslation } from 'react-i18next';

interface Planification {
  id: number;
  name: string;
  description: string;
}

interface GoalCardProps {
  goal?: ClientGoal;
  clientId?: number;
}

const GoalCard: React.FC<GoalCardProps> = ({ goal, clientId }) => {
  const [newGoalRequest, setNewGoalRequest] = useState({ status: 'initial', error: '' });
  const [showForm, setShowForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const [localGoal, setLocalGoal] = useState<ClientGoal | undefined>(goal);
  const { createdAt, trainingPreferences, mainGoal, trainingDuration, availableDays, id } = localGoal || {};
  const { t } = useTranslation();

  const handleNewGoal = async ({ mainGoal, trainingPreferences, availableDays, trainingDuration }: { mainGoal: string, trainingPreferences: string, availableDays: string, trainingDuration: string }) => {
    if (!clientId) return;
    setNewGoalRequest({ status: 'loading', error: '' });
    const res = await api.clients.addGoal(clientId, { mainGoal, trainingPreferences, availableDays, trainingDuration })
    if (res.success) {
      setNewGoalRequest({ status: 'success', error: '' });
      setLocalGoal(res.data);
      setShowForm(false);
    } else {
      setNewGoalRequest({ status: 'error', error: res.error || 'Error adding goal' });
    }
  }
  const isMobile = window.innerWidth < 600;
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6" component="div">
            {t('GoalCard.date', { date: createdAt ? formatDate(createdAt) : '-' })}
          </Typography>
          <Typography variant="body1" component="div">
            <b>{t('GoalCard.trainingPreferences')}</b> {trainingPreferences}
          </Typography>
          <Typography variant="body1" component="div">
            <b>{t('GoalCard.goal')}</b> {mainGoal}
          </Typography>
          {availableDays && (
            <Typography variant="body1" component="div">
              <b>{t('GoalCard.weeklyFrequency')}</b> {availableDays}
            </Typography>
          )}
          {trainingDuration && (
            <Typography variant="body1" component="div">
              <b>{t('GoalCard.sessionDuration')}</b> {`${trainingDuration} ${t('GoalCard.minutes')}`}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button size="small" color="secondary" disabled>
          {t('GoalCard.seeHistory')}
          </Button>

          <Button size="small" color="primary" onClick={() => clientId ? setShowForm(true) : ''}>
          {t('GoalCard.updateGoal')}
          </Button>
        </CardActions>
      </Card>
      
      <Dialog
        open={showForm}
        fullScreen={isMobile}
        TransitionComponent={Transition}
        PaperProps={{ sx: { width: ['100%', '800px'], maxWidth: '100%' } }}
      >
        <DialogTitle>{t('GoalCard.updateGoal')}</DialogTitle>
        <GoalForm onSave={handleNewGoal} onCancel={() => setShowForm(false)} status={newGoalRequest.status} goal={localGoal}/>
      </Dialog>
    </>
  );
};

export default GoalCard;