import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField, CardHeader, CardMedia, Box, TableCell, TableHead, TableRow, TableContainer, Table, TableBody, useTheme, IconButton, Button, Alert, AlertTitle } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Exercise, WorkoutWeekCreate, WorkoutWeekDraft } from '../../api'; // Adjust the import according to your project structure
import { AddOutlined, CheckOutlined, CloseOutlined, DeleteOutline, EditOutlined, SendTimeExtensionRounded } from '@mui/icons-material';
import WorkweekTitle from './WorkweekTitle';
import AddExerciseButton from './AddExercise';
import { useTranslation } from 'react-i18next';

interface WorkoutWeekEditorProps {
  workoutWeek: WorkoutWeekDraft;
  onRetryPlanification?: () => void;
  setWorkoutWeek?: (workoutWeek: WorkoutWeekDraft) => void;
  multipleTrainingId?: number;
}

interface EditState {
  dayIndex: number | null;
  exerciseIndex: number | null;
  repIndex: number | null;
  value: string;
}
export interface ExercisesByDay {
  day: string;
  exercises: { [key: number]: Exercise };
}

const WorkoutWeekEditor: React.FC<WorkoutWeekEditorProps> = ({ workoutWeek, onRetryPlanification, multipleTrainingId }) => {
  const [editState, setEditState] = useState<EditState>({ dayIndex: null, exerciseIndex: null, repIndex: null, value: '' });
  const [exercisesByDay, setExercisesByDay] = useState<ExercisesByDay[]>([]);
  const [exercisesIdByDay, setExercisesIdByDay] = useState<number[][]>([]); // [dayIndex][exerciseIndex]

  const { t } = useTranslation();
  const [name, setName] = useState(`${t('WorkoutweekEditor.planification')} ${workoutWeek?.user?.mainGoal || '-'}`);
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const clientId = workoutWeek?.user?.clientId;
  useEffect(() => {
    if (workoutWeek) {
      setExercisesByDay(workoutWeek.planification.map((day) => ({
        ...day,
        exercises: day.exercises.reduce((acc, exercise) => ({ ...acc, [exercise.id]: exercise }), {}),
      })));
      setExercisesIdByDay(workoutWeek.planification.map((day) => day.exercises.map((exercise) => exercise.id)));
    }
  }, [workoutWeek]);
  
  if (!workoutWeek) {
    return <Typography variant="h6">{t('WorkoutweekEditor.noWorkoutToDisplay')}</Typography>;
  }

  const handleOnDragEnd = (result: { destination: any; source?: any; }) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const dayIndex = source.droppableId.split('-')[1];
    const updatedExercises = exercisesIdByDay[dayIndex];
    const [movedExercise] = updatedExercises.splice(source.index, 1);
    updatedExercises.splice(destination.index, 0, movedExercise);


    setExercisesIdByDay((values) => ({ ...values, [dayIndex]: [ ...updatedExercises ]}));
    //setWorkoutWeek({ ...workoutWeek, planification: updatedPlanification });
  };

  const handleAddExercise = (dayIndex: number, exercise: Exercise) => {
    console.log('add exercise');
    exercise.id = Date.now();
    const updatedExercises = exercisesByDay[dayIndex].exercises;
    updatedExercises[exercise.id] = exercise;
    /*setExercisesByDay((values) => {
      values[dayIndex] = {
        ...values[dayIndex],
        exercises: { ...updatedExercises },
      };
      return [ ...values ];
    });*/
    setExercisesIdByDay((values) => {
      values[dayIndex].push(exercise.id);
      return { ...values };
    });
  }

  const handleDeleteExercise = (dayIndex: number, exerciseIndex: number) => {
    const updatedExercises = exercisesIdByDay[dayIndex].filter((id) => id !== exerciseIndex);
  
    setExercisesIdByDay((values) => ({ ...values, [dayIndex]: updatedExercises }));
    //setWorkoutWeek({ ...workoutWeek, planification: updatedPlanification });
  };

  const handleEditRep = (dayIndex: number, exerciseIndex: number, repIndex: number, value: string) => {
    setEditState({ dayIndex, exerciseIndex, repIndex, value });
  };

  const handleSaveRep = () => {
    const { dayIndex, exerciseIndex, repIndex, value } = editState;
    if (dayIndex === null || exerciseIndex === null) return;
    const updatedExercises = exercisesByDay[dayIndex].exercises[exerciseIndex].reps.map((rep, index) => {
      if (index === repIndex) {
        return value;
      }
      return rep;
    });

    setExercisesByDay((values) => {
      values[dayIndex].exercises[exerciseIndex] = {
        ...values[dayIndex].exercises[exerciseIndex],
        reps: [ ...updatedExercises ],
      };
      return [ ...values ];
    });
    // setWorkoutWeek({ ...workoutWeek, planification: updatedPlanification });
    setEditState({ dayIndex: null, exerciseIndex: null, repIndex: null, value: '' });
  };

  const handleCancelEdit = () => {
    setEditState({ dayIndex: null, exerciseIndex: null, repIndex: null, value: '' });
  };

  const handleDeleteRep = (dayIndex: number, exerciseIndex: number, repIndex: number) => {
    const updatedReps = exercisesByDay[dayIndex].exercises[exerciseIndex].reps.filter((rep, idx) => idx !== repIndex);
    exercisesByDay[dayIndex].exercises[exerciseIndex] = {
      ...exercisesByDay[dayIndex].exercises[exerciseIndex],
      reps: [ ...updatedReps ],
    }
    setExercisesByDay([ ...exercisesByDay ]);
    // setWorkoutWeek({ ...workoutWeek, planification: updatedPlanification });
  };

  return (
    <Grid container spacing={2}>
      {exercisesByDay.map((day, dayIndex) => (
        <Grid item xs={12} key={dayIndex} sx={{ backgroundColor: 'Background', p: 2, my: 2 }}>
          <Typography variant="h5" component="div" textTransform="capitalize">
            {day.day}
          </Typography>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={`day-${dayIndex}`}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {exercisesIdByDay[dayIndex].map((exerciseId, indexReal) => {
                    const exercise = day.exercises[exerciseId];
                    const exerciseIndex = exerciseId;
                    return (
                    <Draggable key={exerciseIndex} draggableId={`exercise-${exerciseIndex}`} index={indexReal}>
                      {(provided) => (
                        <Card
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ marginTop: 1, borderLeft: `3px solid ${primary}`}}
                        >
                          <CardHeader title={exercise.name} action={(
                            <IconButton onClick={() => handleDeleteExercise(dayIndex, exerciseIndex)}>
                              <DeleteOutline />
                            </IconButton>
                          )} />
                          <CardContent>
                            <Box display="flex">
                              <Box display="flex" flexDirection="column" flex="2" alignItems="center">
                                <CardMedia component="img" image="/logo_2.png" alt=".." width={60} sx={{ opacity: 0.6, width: '70px', mb: 2 }} />
                                <caption>{exercise.machineOrMaterial}</caption>
                              </Box>
                              <Box flex="8" ml={2}>
                                <TableContainer>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>{t('WorkoutweekEditor.series')}</TableCell>
                                        <TableCell>{t('WorkoutweekEditor.reps')}</TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {exercise.reps.map((rep, repIndex) => (
                                        <TableRow key={repIndex}>
                                          <TableCell>{repIndex + 1}</TableCell>
                                          <TableCell>
                                            {editState.dayIndex === dayIndex && editState.exerciseIndex === exerciseIndex && editState.repIndex === repIndex ? (
                                              <TextField
                                                value={editState.value}
                                                size="small"
                                                type="number"
                                                sx={{ width: 70 }}
                                                onChange={(e) => setEditState({ ...editState, value: e.target.value })}
                                              />
                                            ) : (
                                              <span style={{ width: 70 }}>{rep}</span>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {editState.dayIndex === dayIndex && editState.exerciseIndex === exerciseIndex && editState.repIndex === repIndex ? (
                                              <>
                                                <IconButton onClick={handleSaveRep}>
                                                  <CheckOutlined />
                                                </IconButton>
                                                <IconButton onClick={handleCancelEdit}>
                                                  <CloseOutlined />
                                                </IconButton>
                                              </>
                                            ) : (
                                              <>
                                                <IconButton onClick={() => handleEditRep(dayIndex, exerciseIndex, repIndex, rep)}>
                                                  <EditOutlined />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteRep(dayIndex, exerciseIndex, repIndex)}>
                                                  <DeleteOutline />
                                                </IconButton>
                                              </>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      )}
                    </Draggable>
                  );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <AddExerciseButton day={dayIndex} onAdd={handleAddExercise}/>
        </Grid>
      ))}

      <Alert severity="success" sx={{ mb: 2 }}>
        <AlertTitle>
          {workoutWeek.motivational}
        </AlertTitle>
      </Alert>
      <WorkweekTitle
        clientId={clientId}
        name={name}
        exercises={{ exercisesByDay, exercisesIdByDay }}
        setName={setName}
        workoutWeek={workoutWeek}
        onRetryPlanification={onRetryPlanification}
        multipleTrainingId={multipleTrainingId}
      />
    </Grid>
  );
};

export default WorkoutWeekEditor;