import React, { useContext, useState } from 'react';
import { Card, CardContent, CardActions, Typography, Accordion, AccordionSummary, AccordionDetails, Button, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemButton, CardHeader, Dialog, DialogTitle, Slide } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formattedTime } from '../../utils/times';
import { formatDate } from '../../utils/dates';
import api, { ClientHealth, WorkoutWeek } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import HealthForm from './health-form';
import { TransitionProps } from '@mui/material/transitions';
import { UserContext } from '../../contexts/userContext';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface Planification {
  id: number;
  name: string;
  description: string;
}

interface HealthCardProps {
  health?: ClientHealth;
  clientId?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const HealthCard: React.FC<HealthCardProps> = ({ health, clientId }) => {
  const [newHealthRequest, setNewHealthRequest] = useState({ status: 'initial', error: '' });
  const [showForm, setShowForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const [localHealth, setLocalHealth] = useState<ClientHealth | undefined>(health);
  const { createdAt, weight, height, injuries, previousExperience } = localHealth || {};
  const { user } = useContext(UserContext);
  const { t } = useTranslation();


  const handleNewHealth = async ({  weight, height, previousInjuries, previousExperience }: { weight: number, height: number, previousInjuries: string, previousExperience: string }) => {
    if (!clientId) return;
    setNewHealthRequest({ status: 'loading', error: '' });
    const age = Math.abs(dayjs(user.birthdate).diff(dayjs(), 'year'));
    const res = await api.clients.addHealth(clientId, { age, weight, height, previousInjuries, previousExperience })
    if (res.success) {
      setNewHealthRequest({ status: 'success', error: '' });
      setLocalHealth(res.data);
      setShowForm(false);
    } else {
      setNewHealthRequest({ status: 'error', error: res.error || t('HealthCard.errorAddingHealth') });
    }
  }

  const isMobile = window.innerWidth < 600;
  return (
    <>
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          {t('HealthCard.date', { date: createdAt ? formatDate(createdAt) : '-'})}
        </Typography>
        <Typography variant="body1" component="div">
          <b>{t('HealthCard.weight')}</b> {weight} kg
        </Typography>
        <Typography variant="body1" component="div">
          <b>{t('HealthCard.height')}</b> {height} cm
        </Typography>
        <Typography variant="body1" component="div">
          <b>{t('HealthCard.injuries')}</b> {injuries ? injuries : t('HealthCard.noInjuries')}
        </Typography>
        <Typography variant="body1" component="div">
          <b>{t('HealthCard.levelPrevExp')}</b> {`${previousExperience}`}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" disabled>
          {t('HealthCard.seeHistory')}
        </Button>
          <Button size="small" color="primary" onClick={() => clientId ? setShowForm(true) : ''}>
            {t('HealthCard.updateHealth')}
          </Button>
      </CardActions>
    </Card>
    <Dialog
      open={showForm}
      fullScreen={isMobile}
      TransitionComponent={Transition}
      PaperProps={{ sx: { width: ['100%', '800px'], maxWidth: '100%' } }}
    >
      <DialogTitle>{t('HealthCard.updateHealthData')}</DialogTitle>
      <HealthForm onSave={handleNewHealth} onCancel={() => setShowForm(false)} status={newHealthRequest.status} health={localHealth}/>
    </Dialog>
    </>
  );
};

export default HealthCard;